










import Vue from "vue";
import { mapGetters } from "vuex";
import { GET_USER_DETAILS } from "@/store/modules/auth/constants";
import { HelloUser } from "@/interfaces/shared/hello_user";

export default Vue.extend({
  name: "HelloUser",
  data(): HelloUser {
    return {
      hello_illustration: require("@/assets/illustrations/waving-hand.svg")
    };
  },
  computed: {
    ...mapGetters("auth", {
      user: GET_USER_DETAILS
    })
  }
});
