








































































import Vue from "vue";

import DataNotFound from "@/components/shared/DataNotFound.vue";
import { user_consumption_time, get_name_initials } from "@/utils/global";

import { mapActions, mapGetters } from "vuex";
import {
  DASHBOARD_USER_CONSUMPTION,
  USER_MANAGEMENT_LISTING
} from "@/store/modules/recruiter/constants";
export default Vue.extend({
  name: "TotalCandidates",
  components: { DataNotFound },
  data() {
    return {
      loading: false
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters("recruiter", {
      fetch_user_data: DASHBOARD_USER_CONSUMPTION
    })
  },
  methods: {
    get_name_initials,
    user_consumption_time,
    ...mapActions("recruiter", {
      fetch_user_listing: USER_MANAGEMENT_LISTING
    }),
    async init() {
      this.loading = true;
      await this.fetch_user_listing({
        page: 1,
        limit: 12,
        is_dashboard: true
      });
      this.loading = false;
    }
  }
});
