
























































































import Vue from "vue";
import HelloUser from "@/components/shared/HelloUser.vue";
import TotalCandidates from "@/components/recruiter/dashboard/TotalCandidates.vue";
import TimeConsumptionStatistics from "@/components/recruiter/dashboard/TimeConsumptionStatistics.vue";
import { mapActions } from "vuex";
import { DASHBOARD_STATS_API } from "@/store/modules/recruiter/constants";
import { StatsUserManagement } from "@/store/modules/recruiter/interfaces";
import {
  clean_string,
  format_consumption_time_in_hours_min_sec
} from "@/utils/global";
import moment from "moment";
import DataNotFound from "@/components/shared/DataNotFound.vue";
import { TimeSecondInterface } from "@/store/modules/common/interfaces";

export default Vue.extend({
  name: "DigitalHumanDashboard",
  components: {
    TotalCandidates,
    HelloUser,
    TimeConsumptionStatistics,
    DataNotFound
  },
  data() {
    return {
      stat_error: false,
      stat_loading: true,
      chart_data_members: [],
      loading: false,
      overall_consumption: {} as TimeSecondInterface
    };
  },
  async mounted() {
    const filter = sessionStorage.getItem("filter");
    if (filter && clean_string(filter) === "monthly") {
      const end_date = moment().add(1, "day");
      const start_date = moment().subtract(30, "days");
      const payload: StatsUserManagement = {
        start_date: start_date.format("YYYY-MM-DD"),
        end_date: end_date.format("YYYY-MM-DD")
      };
      await this.fetch_stats(payload);
    } else {
      const end_date = moment().add(1, "day");
      const start_date = moment().subtract(7, "days");
      const payload: StatsUserManagement = {
        start_date: start_date.format("YYYY-MM-DD"),
        end_date: end_date.format("YYYY-MM-DD")
      };
      await this.fetch_stats(payload);
    }
  },
  methods: {
    ...mapActions("recruiter", {
      get_stats_of_user_consumption: DASHBOARD_STATS_API
    }),
    /**
     * Function to fetch stats
     */
    async fetch_stats(payload: StatsUserManagement) {
      this.stat_loading = true;
      if (payload.start_date && payload.end_date) {
        const dates = [payload.start_date, payload.end_date];
        const data = await this.get_stats_of_user_consumption(dates);
        if (data) {
          this.chart_data_members = data.consumption_data.map((item: any) => {
            const day = Object.keys(item)[0];
            const count = Math.floor(item[day] / 60000);
            return { day, count };
          });
          this.overall_consumption = format_consumption_time_in_hours_min_sec(
            data.overall_consumption
          );
        } else {
          this.stat_error = true;
        }
      }
      this.stat_loading = false;
    }
  }
});
